<template>
  <aside class="root">
    <BaseSidebar
      :title="$t('個人設定')"
      :menus="[
        {
          to: { name: 'organizationId-myself-edit' },
          text: $t('基本情報'),
        },
        {
          to: { name: 'organizationId-myself-passwordEdit' },
          text: $t('パスワード変更'),
        },
      ]"
    />
  </aside>
</template>

<style scoped lang="scss">
.root {
  width: 260px;
}
</style>

<template>
  <div>
    <SimpleHeader @back="() => navigateTo('/')" />
    <TheSnackbar />
    <div class="columns">
      <nav class="sidebar is-narrow hero is-fullheight">
        <MyselfSidebar />
      </nav>
      <main class="column">
        <slot />
      </main>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.columns {
  margin: 0;
}

.sidebar {
  border-right: 1px solid hsl(0, 0%, 90%);
}
</style>
